<template>
  <v-container class="login-page" fluid>
    <v-row>
      <v-col cols="6" offset="3" xl="4" offset-xl="4" align="center">
        <img src="@/assets/images/logo.svg" alt="Beeward" class="logo" />
        <v-card class="py-12" align="center">
          <v-form ref="form" v-model="isValid" class="login-form" @submit.prevent="login">
            <h1 class="display-3 mb-10">Belépés</h1>
            <v-text-field
              type="email"
              v-model="email"
              label="Email"
              :rules="[requiredRule, emailRule]"
              required
            ></v-text-field>
            <v-text-field
              type="password"
              v-model="password"
              label="Jelszó"
              :rules="[requiredRule]"
              required
            ></v-text-field>
            <v-btn type="submit" class="mt-8" x-large color="primary" :loading="isLoading">Belépés</v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import { AUTH_REQUEST, GET_USER_REQUEST } from '@/store/types'
import AuthService from '@/services/auth.service'
import formControlMixin from '@/mixins/formControl'

export default {
  name: 'PageLogin',
  mixins: [formControlMixin],
  data() {
    return {
      email: '',
      password: ''
    }
  },
  computed: {
    ...mapState({
      status: state => state.auth.status
    }),
    isLoading() {
      return this.status === 'loading'
    }
  },
  methods: {
    async login() {
      this.validate()

      if (!this.isValid) return

      try {
        await this.$store.dispatch(AUTH_REQUEST, {
          email: this.email,
          password: this.password
        })
        this.$router.push({ path: AuthService.HOME_ROUTE })
        this.$store.dispatch(GET_USER_REQUEST)
      } catch (error) {
        console.log('err')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.login-page {
  height: 100vh;
  display: flex;
  align-items: center;
}

.logo {
  width: 150px;
  margin-bottom: 30px;
  margin-top: -80px;
}

.login-form {
  padding: 0 60px;
}
</style>
